import React, { Component } from 'react'

import Tooltip from '../tooltip'

class StatusInfo extends Component {

  constructor() {
    super()
    this.state = {
      open: false
    }
  }

  toggle = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  render() {
    const { info, children } = this.props
    const { open } = this.state


    return (
      <div className="status-item" onMouseEnter={this.toggle} onMouseLeave={this.toggle}>
        <Tooltip open={open} modifier="top">
          {info}
        </Tooltip>
        {children}
      </div>
    )

  }
}

export default StatusInfo
