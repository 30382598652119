import React, { useContext, useState } from 'react'
import { withPrefix } from 'gatsby'

import ClientName from './../client/name'
import Icon from './../icon'
import LocationContext from './../../context/location';
import TaSelect from '../field/taSelect'
import { appointmentSave } from '../../services/appointmentUpdate';
import MessagesContext from '../../context/messages'
import { get } from '../../utils/objects'
import StatusInfo from '../client/statusInfo';
import Currency from '../currency';
import moment from "moment-timezone";


const CalendarAppt = ({ data, id, showTime = false, refresh = ()=>{} }) => {
  const location = useContext(LocationContext);
  const { setMessages } = useContext(MessagesContext);

  const setTa = ta => {
    data.ta = ta;
  }
  const image = (data.ta && data.ta.image)
    ? data.ta.image
    : withPrefix('/img/profile.png');

  const onTaChange = (event) => {
    const prevTa = data.ta;
    setTa({
      ...data.ta,
      id: event.target.value
    });

    const value = {
      ta: {
        id: event.target.value,
      },
    }

    appointmentSave(data.id, value)
      .then((response) => {
        setTa(get(response, 'data.ta'));
        data.ta = value.ta;
        setMessages([{
          type: 'status',
          text: 'Appointment Updated'
        }]);
        refresh();
      })
      .catch(() => {
        setMessages([{
          type: 'error',
          text: 'Unable to update appointment'
        }])
        setTa(prevTa);
      });
  }

  return (
  <div className={`calendar__cell calendar__cell--${data.today ? 'today' : data.past ? 'past' : 'future'} calendar-appt calendar-appt-${id} calendar-appt-${data.day}`}>
    <div className="calendar-appt-cell">
      <div className={"calendar-appt-cell__status client-status"}>
        <Icon icon="appt" theme={data.appointment} value={data.appointment} />
        <div className="status__payment" data-status="none">
          {data.appointment === "1" &&
              <StatusInfo
                  info={
                    <Currency amount={data.orderDetails.paid}/>
                  }
              >
                {data.orderDetails.complete
                    ?
                    <Icon icon="accounting" theme="secondary" modifier="check"/>
                    :
                    <Icon
                        icon={data.orderDetails.paid <= 0 ? "accounting" : "deposit"}
                        theme={data.orderDetails.paid <= 0 ? "disabled" : "none"}/>

                }
              </StatusInfo>
          }
        </div>
      </div>
      {data.client
       ? <ClientName name={data.client.name} id={data.client.id} />
       : <p>Client Missing</p>}
      <div className="client__app-ta">
        {data.ta
        ?
          <>
            <div className="client__app-ta-image">{(image && data.ta.name) && <img width="24" height="24" src={decodeURIComponent(image)} alt={data.ta.name} />}</div>
            <div className="client__app-ta-name"><TaSelect value={data.ta.id} onChange={onTaChange} /></div>
          </>
        :
          <div className="client__app-ta-name"><TaSelect onChange={onTaChange} /></div>
        }
      </div>
      {showTime ?
      <div className={'client__time'}>
        <div className="time-icon">
          <Icon icon="time"/>
        </div>
        <p className="client__app-time">
          {moment
          .utc(data.date.start)
          .tz(location.timezone(data.location.id))
          .format('h:mmA')
          }&mdash;
          {moment.utc(data.date.end)
          .tz(location.timezone(data.location.id))
          .format('h:mmA')}
        </p>
      </div> : ''
      }
  </div>
  </div>
  );
}

export default CalendarAppt
