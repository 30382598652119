import React from 'react'

const Tooltip = ({ children, open, modifier = '' }) => (
  <div className={`tooltip tooltip__${open ? 'is-open' : 'is-closed'} tooltip__${modifier}`}>
    <div className="tooltip-inner">
      <div className="tooltip-content">
        {children}
      </div>
    </div>
  </div>
);

export default Tooltip
